import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import useState from "react-hook-use-state";
import $ from "jquery";
import { Link, useLocation } from "react-router-dom";
import Chat from '../../chat';
import "./Header.css";


// api file
import * as Constant from '../../Constant'
import * as Constant2 from '../../Constant2'
import Skeleton from "react-loading-skeleton";



function Header(props) {

      const [posts, setPosts] = useState([]);
      const [loading ,setLoading] = useState(true);
     
      const fetchPost = async () => {
        await fetch(Constant.ShowSetting, {
        method: "GET",
        
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success === true) {
            setPosts({
              title: data.data[0].title,
              image: data.data[0].image,
              meta_content: data.data[0].meta_content,
              
            });
      //  var content = console.log('Shagil..',posts.meta_content)  
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error.message === "Failed to fetch") {
          } else {
            console.error("Error:!!!!", error);
          }
        });
    };
   
      useEffect(() => {
        fetchPost();
      }, []);




  useEffect(() => {
    $(".scroll_down2").click(function () {
      console.log("click");
      var sectionTo = $(this).attr("href");
      $("html, body").animate(
        {
          scrollTop: $(sectionTo).offset().top,
        },
        500
      );
    });
  });

  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    if (sidebar === true) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  };

  const [isActive, setIsActive] = useState("home");
  const handleClick2 = () => {
    if (isActive >= 100) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const handleClick22 = () => {
    if (isActive <= 100) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const [sideba, setSideba] = useState(false);

  const showSideba = () => {
    if (sideba === true) {
      setSideba(false);
    } else {
      setSideba(true);
    }
  };

  const [navbar, setNavbar] = useState(false);

  const changeBg = () => {
    if (window.scrollY >= 5) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBg);

  const [showTopBtn, setShowTopBtn] = useState(true);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(true);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClick = () => {
    setToggle(!toggle);
  };

  function testFunctionHome() {
    goToTop();
    showSideba();
    handleClick();
  }
  function testFunction() {
    goToTop();
    showSideba();
    handleClick();
    handleClick2();
  }
  function testFunction2() {
    goToTop();
    showSideba();
    handleClick();
    handleClick22();
  }

  const [toggle, setToggle] = useState(true);

  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <>
    
      <body data-bs-spy="scroll" data-bs-target="#navbar" data-bs-offset="71">
      
        {/* {api.slice(0,1).map((items) => {
          return ( */}
            <Helmet>
            <meta charSet="utf-8" />
              <title>{posts.title}</title>
              <meta name="description" content={posts.meta_content} />
            </Helmet>

           
            

          {/* );
          })} */}

        <nav
          className={
            navbar
              ? "navbar shadow navbar-expand-lg fixed-top navbar-custom sticky sticky-light bg-light"
              : "navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-light bg-light bg-transparent"
          }
          id="navbar"
        >
          <div class="container">
            {showTopBtn && (
              <Link
                class="navbar-brand logo text-uppercase"
                to="/"
                onClick={goToTop}
              >
                 { loading === true ? 
                 
                 <Skeleton class="logo-dark" height={40} width={160}  count={1}/>
                 :

                <img
                 
                   src={Constant2.ShowStorage + posts.image}
                  class="logo-dark"
                  alt=""
                  // height="60"
                  // width="245"
                  style={{ width:'60px'}}
                />
                 }
              </Link>

            )}{" "}
            <button
              className={
                sideba === false ? "navbar-toggler collapsed" : "navbar-toggler"
              }
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              onClick={showSidebar}
              aria-label="Toggle navigation"
              id="btm123"
            >
              <span className={sidebar === false ? "open" : "close"}></span>
            </button>
            {isActive && splitLocation[1] === "" ? (
              <div
                class={
                  sidebar === false
                    ? "navbar-collapse collapse"
                    : "navbar-collapse collapse show"
                }
                id="navbarCollapse"
              >
                <ul class="navbar-nav ms-auto" id="navbar-navlist">
                  <li class="nav-item" onClick={showSidebar}>
                    {" "}
                    {showTopBtn && (
                      <Link
                        className={navbar ? "nav-link" : "nav-link text-white"}
                        onClick={testFunctionHome}
                        to="/"
                      >
                        Home
                      </Link>
                    )}{" "}
                  </li>

                  <li class="nav-item" onClick={showSidebar}>
                    <Link
                      class={navbar ? "nav-link" : "nav-link text-white"}
                      onClick={testFunction}
                      to="/about"
                    >
                      About
                    </Link>
                  </li>
                  <li class="nav-item" onClick={showSidebar}>
                    <Link
                      class={navbar ? "nav-link" : "nav-link text-white"}
                      onClick={testFunction}
                      to="/portfolio"
                    >
                      Portfolio
                    </Link>
                  </li>
                  <li class="nav-item" onClick={showSidebar}>
                    <Link
                      class={navbar ? "nav-link" : "nav-link text-white"}
                      onClick={testFunction}
                      to="/service"
                    >
                      Services
                    </Link>
                  </li>
                </ul>

                <div class="contact-btn" onClick={showSidebar}>
                  <Link
                    className="btn bg-gradiant"
                    style={{ marginRight: "0" }}
                    onClick={testFunction}
                    to="contact"
                  >
                    Contact
                  </Link>
                </div>
              </div>
            ) : (
              <div
                class={
                  sidebar === false
                    ? "navbar-collapse collapse"
                    : "navbar-collapse collapse show"
                }
                id="navbarCollapse"
              >
                <ul class="navbar-nav ms-auto" id="navbar-navlist">
                  <li class="nav-item" onClick={showSidebar}>
                    {" "}
                    {showTopBtn && (
                      <Link className="nav-link" onClick={testFunction2} to="/">
                        Home
                      </Link>
                    )}{" "}
                  </li>

                  <li class="nav-item" onClick={showSidebar}>
                    <Link class="nav-link" onClick={testFunction} to="/about">
                      About
                    </Link>
                  </li>
                  <li class="nav-item" onClick={showSidebar}>
                    <Link
                      class="nav-link"
                      onClick={testFunction}
                      to="/portfolio"
                    >
                      Portfolio
                    </Link>
                  </li>
                  <li class="nav-item" onClick={showSidebar}>
                    <Link class="nav-link" onClick={testFunction} to="/service">
                      Services
                    </Link>
                  </li>
                </ul>

                <div class="contact-btn" onClick={showSidebar}>
                  <Link
                    className="btn bg-gradiant"
                    style={{ marginRight: "0" }}
                    onClick={testFunction}
                    to="contact"
                  >
                    Contact
                  </Link>
                </div>
              </div>
            )}
           <div>
              <Chat/>
              </div> 
              </div>
        </nav>
      </body>
    </>
  );
}

export default Header;
