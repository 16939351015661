// export const BaseUrlApi = "http://localhost/Admin_Portfolio/public/"
export const BaseUrlApi = "https://admin.gauravvaghela.com/public/"

export const ShowStorage = BaseUrlApi + ''
export const ShowImages = BaseUrlApi + 'images/'

// export const BaseUrl = "http://localhost:3000/"
export const BaseUrl = "https://gauravvaghela.com/"
// export const BaseUrl = "http://localhost/Admin_Portfolio/public/"

