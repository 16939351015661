import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
// import { Helmet } from "react-helmet";
import Carousel1 from "react-bootstrap/Carousel";
import img7 from "../../assets/images/user3.jpg";
import { DynamicStar } from "react-dynamic-star";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// import axios from "axios";

// api file
import * as Constant from "../Constant";
import * as Constant2 from "../Constant2";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      service: [],
      setting: [],
      portflio: [],
      testimonial: [],
      settingsub: "",
      settingdes: "",

      settingimage: "",
      settingname: "",
      settingdescription: "",
      settingmeta_content:"",
      settingdownload: "",
      title2: "",
      isLoading: true,
    };
  }

  getSettings = async () => {
    await fetch(Constant.ShowSetting, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            isLoading: false,
            // settingmain: data.data[0].mainheading,
            settingsub: data.data[0].subheading,
            settingdes: data.data[0].description,
            settingmeta_content: data.data[0].meta_content,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  getAbouts = async () => {
    await fetch(Constant.ShowAbout, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            isLoading: false,
            settingimage: data.data[0].image,
            settingname: data.data[0].name,
            settingdescription: data.data[0].description,
            settingdownload: data.data[0].download,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  getServices = async () => {
    await fetch(Constant.ShowServices, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            isLoading: false,
            service: data.data,
            imageService: data.data[0].image,
            nameService: data.data[0].name,
            descriptionService: data.data[0].description,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  getPortflio = async () => {
    await fetch(Constant.ShowPortfolios, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            isLoading: false,
            portflio: data.data,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  getTestimonial = async () => {
    await fetch(Constant.ShowTestimonial, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            isLoading: false,
            testimonial: data.data,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  getTitle = async () => {
    await fetch(Constant.ShowSetting,
     {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            title2: data.data[0].title,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
    };
  componentDidMount() {
    this.getPortflio();
    this.getSettings();
    this.getAbouts();
    this.getServices();
    this.getTestimonial();
    this.getTitle();
  }
  render() {
    return (
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <>
        <Helmet>
      <meta charSet="utf-8" />
      <title>{this.state.title2} - Home</title>
      <meta name="description" content={this.state.settingmeta_content}/>
    </Helmet>
          <section>
            <header id="home" class="header">
              <div class="overlay1"></div>
              <div className="row mx-auto">
                <div className="col-md-12 pb-5">
                  {/* { this.state.setting.slice(0,1).map((item,index) =>{
                return (  */}

                  {this.state.isLoading === true ? (
                    <div class="header-content container">
                      <div>
                        {" "}
                        <Skeleton
                          className="header-content container mb-2 sktln"
                          baseColor="#255f51"
                          highlightColor="#255f51"
                          height={30}
                          width={500}
                          count={1}
                        />
                      </div>
                      <h1 class="header-title">
                        <Skeleton
                          className="down d-flex"
                          height={35}
                          count={1}
                        />
                        {/* <span class="down d-flex"></span> */}
                      </h1>
                      <Skeleton
                        className="header-subtitle d-flex"
                        height={35}
                        count={1}
                      />
                      {/* <p class="header-subtitle d-flex mt-2"></p> */}
                      <Link className="btn bg-gradiant" to="portfolio">
                       Visit My Works
                      </Link>
                    </div>
                  ) : (
                    <div class="header-content container">
                      <h1 class="header-title">
                        <span class="">{this.state.settingsub}</span>
                      </h1>

                      <p class="header-subtitle d-flex mt-2">
                        {this.state.settingdes}
                      </p>

                      <Link className="btn bg-gradiant" to="portfolio">
                        Visit My Works
                      </Link>
                    </div>
                  )}

                  {/* );
          })} */}
                </div>
              </div>
            </header>
          </section>

          {/* abouts */}
          {this.state.isLoading === true ? (
            <section className="container my-5">
              <div className="row mt-5">
                <div className="col-md-4">
                  <Skeleton
                    className="d-lg-flex fw-bold fontt-color"
                    height={300}
                    width={300}
                    count={1}
                  />
                </div>
                <div className="col-md-8">
                  <div className="row mt-3">
                    <div className="col-md-12">
                      {/* <h6 className="d-lg-flex opacity">Who Am I ?</h6> */}
                      <Skeleton
                        className="d-lg-flex opacity"
                        height={30}
                        width={100}
                        count={1}
                      />
                      <Skeleton
                        className="d-lg-flex fw-bold fontt-color"
                        height={40}
                        width={150}
                        count={1}
                      />

                      <Skeleton className="d-lg-flex" height={25} count={4} />
                      <div className="d-lg-flex">
                        <Skeleton
                          className="btn-rounded btn btn-outline-primary mt-4"
                          height={50}
                          width={150}
                          count={1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <section className="container my-5">
              <div className="row mt-5">
                <div className="col-md-4">
                  <img
                    src={Constant2.ShowStorage + this.state.settingimage}
                    alt="Images is not Load"
                  />
                </div>
                <div className="col-md-8">
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <h6 className="d-lg-flex opacity">Who Am I ?</h6>
                      <h1 className="d-lg-flex fw-bold fontt-color">
                        {this.state.settingname}
                      </h1>
                      <div className="d-lg-flex">
                        <p className="text-start mt-3">
                        <div dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(this.state.settingdescription)}} />
                        </p>
                      </div>
                      <div className="d-lg-flex">
                        <a
                          className="btn-rounded btn btn-outline-primary mt-4"
                          href={Constant2.ShowStorage + this.state.settingdownload}
                          download
                        >
                          Download CV
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* service */}

          <section className="section container my-5" id="service">
            <h6 className="opacity mt-5">What I Do ?</h6>
            <h1 className="fw-bold fontt-color">Service</h1>

            { this.state.isLoading === true ? 

<div class="row justify-content-center mt-5">
<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
    
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>
 
<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>
</div>

:

              <div className="row justify-content-center mt-5">
                {
                  this.state.service.map((service,i) => {
                    return (
                      <div className="col-lg-4 col-md-4 wow fadeInUp mt-2">
                        <div className="service-item position-relative h-100">
                          <div
                            className="service-text rounded p-5 h-100"
                          >
                            <div className="mx-auto mb-1">

                              <img className="aboutimg" src={Constant2.ShowStorage + service.image} alt="" />
                            </div>
                            <h4 className="mb-3 text fw-bold">{service.name}</h4>
                            <p className="mb-0 fontamy" style={{ textAlign:'justify',fontSize: '15.75px',color: '#0F7A95' }}>  {service.description} </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>

              }
          </section>
         

          {/* portfolio */}
          <section className="section container mb-5" id="portfolio">
            <h6 className="opacity  mt-5">What I Do ?</h6>
            <h1 className="fw-bold fontt-color">Portfolio</h1>

            {this.state.isLoading === true ? (
              <div className="row mt-3">
                <div class="col-md-4">
                  <a href="#" class="portfolio-card">
                    <Skeleton
                      className="d-lg-flex opacity"
                      height={350}
                      width={350}
                      count={1}
                    />
                  </a>
                </div>
                <div class="col-md-4">
                  <a href="#" class="portfolio-card">
                    <Skeleton
                      className="d-lg-flex opacity"
                      height={350}
                      width={350}
                      count={1}
                    />
                  </a>
                </div>
                <div class="col-md-4">
                  <a href="#" class="portfolio-card">
                    <Skeleton
                      className="d-lg-flex opacity"
                      height={350}
                      width={350}
                      count={1}
                    />
                  </a>
                </div>
              </div>
            ) : (
              <div className="row mt-3">
                {this.state.portflio.slice(0, 3).map((item, i) => {
                  return (
                    <div class="col-md-4 mt-5">
                      <a
                        href="#"
                        class="portfolio-card border border-info rounded"
                        style={{ height: "366px" }}
                      >
                        <img
                          src={Constant2.ShowImages + item.img}
                          class="portfolio-card-imgp" 
                          alt=" Landing page"
                        />
                        <Link
                          class="portfolio-card-overlay"
                          //   to='portfoliodetail'
                          to={`/portfoliodetail/${item.id}`}
                        >
                          <span class="portfolio-card-caption">
                            <h4 className=" fw-bold fontt-color">
                              {item.title}
                            </h4>
                            <p class="font-weight-normal">
                              Category: {item.category}
                            </p>
                          </span>
                        </Link>
                      </a>
                    </div>
                    
                  );
                })}
                <div className="row justify-content-center">
                <div className="col-md-4 mt-3">
                  <Link className="btn-rounded btn btn-outline-primary mt-2" to="/portfolio">Check More</Link>
                </div>
                </div>
              </div>
            )}
          </section>

          <section class="section-sm bg-primary my-5">
            <div class="container text-center text-sm-left">
              <div class="row align-items-center">
                <div class="col-sm offset-md-1 mb-4 mb-md-0">
                  <h6 class="title text-light fw-bold">
                    Want to work with me?
                  </h6>
                  <p class="m-0 text-light">
                    Always feel Free to Contact &amp; Hire me
                  </p>
                </div>
                <div class="col-sm offset-sm-2 offset-md-3">
                  <Link
                    class="btn btn-lg my-font btn-light rounded"
                    to="/contact"
                  >
                    Hire Me
                  </Link>
                </div>
              </div>
            </div>
          </section>

          {/* testmonial */}

          <section class="section bg-light testimonial mt-5" id="testimonial">
            <div class="container mt-5">
              <div class="row align-items-cente">
                <div class="col-lg-3">
                  <div class="title">
                    <p class=" text-primary fw-bold mb-2 fontt-color">
                      Client Testimonial{" "}
                      <i class="mdi mdi-cellphone-iphone"></i>
                    </p>
                    <h3>What they think of us!</h3>
                    <p class="text-muted para-width f-15 mt-3 mb-0"></p>
                  </div>
                </div>

                <div class="col-lg-9">
                  <div class="tns-outer" id="testi-slider-ow">
                    <div id="testi-slider-mw" class="tns-ovh">
                      <div class="tns-inner" id="testi-slider-iw">
                        {this.state.isLoading === true ? (
                          <div
                            class="testi-slider  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal mystyle"
                            id="testi-slider"
                          >
                            <Carousel1>
                              <Carousel1.Item>
                                <div class="item">
                                  <div class="position-relative overflow-hidden">
                                    <div class="row align-items-start">
                                      <Carousel1.Caption>
                                        <div class="media mohit d-flex">
                                          <Skeleton
                                            class="overview fw-bold text-start"
                                            height={80}
                                            width={80}
                                            count={1}
                                          />
                                          {/* <img src={img7} class="mr-3" alt="loading" /> */}
                                          <div class="media-body">
                                            <div class="testimonial">
                                              {/* <p class="overview fw-bold text-start"><b>{item.name}</b></p> */}
                                              <Skeleton
                                                class="overview fw-bold text-start"
                                                height={30}
                                                width={90}
                                                count={1}
                                                style={{ marginLeft: "-300px" }}
                                              />
                                              <p class="text-wrap text-testi justify-content-center">
                                                {" "}
                                              </p>
                                              <Skeleton
                                                class="text-wrap sktnlon2testii"
                                                height={20}
                                                width={196}
                                                count={3}
                                                style={{ marginRight: "182px" }}
                                              />
                                              <p class="overview mt-3"></p>
                                            </div>
                                          </div>
                                        </div>

                                        {/* </div> */}
                                      </Carousel1.Caption>
                                    </div>
                                  </div>
                                </div>
                              </Carousel1.Item>
                            </Carousel1>
                          </div>
                        ) : (
                          <div
                            class="testi-slider  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal mystyle"
                            id="testi-slider">
                            <Carousel1>
                              {this.state.testimonial.map((item) => {
                                return (
                                  // <Carousel.Item interval={1000} infiniteLoop touch={true}>

                                  <Carousel1.Item>
                                    <div class="item">
                                      <div class="position-relative overflow-hidden">
                                        <div class="row align-items-start">
                                          <Carousel1.Caption>
                                            {/* <div class="col-md-12"> */}
                                            <div class="media mohit d-flex">
                                              <img
                                                src={img7}
                                                class="mr-3"
                                                alt="loading"
                                              />
                                              <div class="media-body">
                                                <div class="testimonial">
                                                  <p class="overview fw-bold text-start ">
                                                    <b>{item.title}</b>
                                                  </p>
                                                  <p class="text-wrap text-testi justify-content-center">
                                                    {" "}
                                                    {item.text}
                                                  </p>
                                                  <p class="overview mt-3">
                                                    <DynamicStar
                                                      fullStarColor={"#0F7A95"}
                                                      height={25}
                                                      width={25}
                                                      rating={item.star}
                                                    />
                                                  </p>
                                                </div>
                                              </div>
                                            </div>

                                            {/* </div> */}
                                          </Carousel1.Caption>
                                        </div>
                                      </div>
                                    </div>
                                  </Carousel1.Item>
                                );
                              })}
                            </Carousel1>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      </SkeletonTheme>
    );
  }
}

export default Home;
