import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import './Portfolio.css';

// api file
import * as Constant from "../Constant";
import * as Constant2 from "../Constant2";
import { Helmet } from 'react-helmet';

class Portfolio extends Component {
    constructor() {
        super();
        this.state = {
            portflio: [],
            title2:'',
            isLoading:true,
        };
    }

    viewEmployee(id){
        this.props.history.push(`/portfoliodetail/${id}`);
    }

    getPortflio = async () => {
        await fetch(Constant.ShowPortfolios, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success === true) {
              this.setState({
               isLoading:false,
               portflio: data.data,
              });
              console.log('dataNew', this.state.portflio)
            }
          })
          .catch((error) => {
            if (error.message === "Failed to fetch") {
            } else {
              console.error("Error:!!!!", error);
            }
          });
      };

      getTitle = async () => {
        await fetch(Constant.ShowSetting,
         {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success === true) {
              this.setState({
                title2: data.data[0].title,
              });
            }
          })
          .catch((error) => {
            if (error.message === "Failed to fetch") {
            } else {
              console.error("Error:!!!!", error);
            }
          });
        };


    componentDidMount() {
        this.getPortflio();
        this.getTitle();
      }
  render() {

    return (

      
      <section className="section container mb-5" id="portfolio">
          <Helmet>
      <meta charSet="utf-8" />
      <title>{this.state.title2} - Portfolio</title>
    </Helmet>

      <h6 className="opacity  mt-5">What I Do ?</h6>
      <h1 className="fw-bold fontt-color">Portfolio</h1>

     
        { this.state.isLoading === true ? 
         <div className="row mt-3">
        <div class="col-md-4">
        <a href="#" class="portfolio-card">
        <Skeleton className="d-lg-flex opacity" height={350} width={350} count={1}/>
        </a>
    </div>
        <div class="col-md-4">
        <a href="#" class="portfolio-card">
        <Skeleton className="d-lg-flex opacity" height={350} width={350} count={1}/>
        </a>
    </div>
        <div class="col-md-4">
        <a href="#" class="portfolio-card">
        <Skeleton className="d-lg-flex opacity" height={350} width={350} count={1}/>
        </a>
    </div>
    </div>

    
       
        :
        <div className="row mt-3">
        {
              this.state.portflio.map((item, i) => {
             return (
       
      <div class="col-md-4 mt-5">
                  <a href="#" class="portfolio-card border border-info rounded" style={{ height:'366px' }}>
                      <img src={Constant2.ShowImages + item.img} class="portfolio-card-imgp" alt=" Landing page"/>
                      <Link class="portfolio-card-overlay" 
                    //   to='portfoliodetail'
                      to={(`/portfoliodetail/${item.id}`)}>
                    
                          <span class="portfolio-card-caption">
                              <h4 className=" fw-bold fontt-color">{item.title}</h4>
                                  <p class="font-weight-normal">Category: {item.category}</p>
                          </span>
                      </Link>
                  </a>
              </div>
              );
            })} 
      </div>
    }
    </section>
    
  )
}
}

export default Portfolio;
    