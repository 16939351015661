import React from "react";
// import Carousel1 from 'react-bootstrap/Carousel';
// import { Carousel } from "react-responsive-carousel";
// import img7 from "../../assets/images/user3.jpg";

// api file
// import * as Constant from "./Constant";
// import { DynamicStar } from "react-dynamic-star";
import './Testmonial.css';

class Testmonial extends React.Component {
  constructor() {
    super();
    this.state = {
    
    };
  }

  componentDidMount() {
  }
  render() {

    return (
      <>
        <React.Fragment> 

        </React.Fragment>

      </>
    );
  }
}

export default Testmonial;


