import React from 'react';
import '../../App.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

// api file
import * as Constant from "../Constant";
import * as Constant2 from '../Constant2';
import { Helmet } from 'react-helmet';
import DOMPurify from 'dompurify';


class About extends React.Component {
  constructor() {
    super();
    this.state = {
      settingimage: "",
      settingname: "",
      settingdescription: "",
      settingdownload: "",
      title2: "",
      isLoading:true,
    };
  }
  
  getAbouts = async () => {
    await fetch(Constant.ShowAbout, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            isLoading:false,
            settingimage: data.data[0].image,
            settingname: data.data[0].name,
            settingdescription: data.data[0].description,
            settingdownload: data.data[0].download,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  getTitle = async () => {
    await fetch(Constant.ShowSetting,
     {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            title2: data.data[0].title,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
    };
    

  componentDidMount() {
    this.getAbouts(); 
    this.getTitle(); 
  }
  render() {
    return (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{this.state.title2} - About</title>
    </Helmet>
      

    {this.state.isLoading === true ?
          
          <SkeletonTheme className="basecolor">
    <section className="container my-5">
     <div className="row mt-5">
       <div className="col-md-4">
         <Skeleton className="d-lg-flex fw-bold fontt-color" height={300} width={300}  count={1}/>
       </div>
       <div className="col-md-8">
         <div className="row mt-3">
           <div className="col-md-12">
             {/* <h6 className="d-lg-flex opacity">Who Am I ?</h6> */}
             <Skeleton className="d-lg-flex opacity" height={30} width={100} count={1}/>
             <Skeleton className="d-lg-flex fw-bold fontt-color" height={40} width={150} count={1}/>

               <Skeleton className="d-lg-flex" height={25}  count={4}/>
             <div className="d-lg-flex">
              <Skeleton className="btn-rounded btn btn-outline-primary mt-4" height={50} width={150} count={1}/>
             </div>
           </div>
         </div>
       </div>
 
     </div>
     </section>
     </SkeletonTheme>
        : 

    <section className="container my-5">
    <div className="row mt-5">
      <div className="col-md-4">
        <img src={Constant2.ShowStorage + this.state.settingimage} alt="Images is not Load" />
      </div>
      <div className="col-md-8">
        <div className="row mt-3">
          <div className="col-md-12">
            <h6 className="d-lg-flex opacity">Who Am I ?</h6>
            <h1 className="d-lg-flex fw-bold fontt-color">{this.state.settingname}</h1>
            <div className="d-lg-flex">
            <p className="text-start mt-3">
              <div dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(this.state.settingdescription)}} />
              </p>
            </div>
            <div className="d-lg-flex">
              <a className="btn-rounded btn btn-outline-primary mt-4" target="_blank" href={Constant2.ShowStorage + this.state.settingdownload} download>
                Download CV
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
   }
  </>
  );
}
}
  
  export default About;

  
  