import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './Service.css';

// api file
import * as Constant from "../Constant";
import * as Constant2 from "../Constant2";
import { Helmet } from 'react-helmet';

 class Service extends React.Component {
  constructor() {
    super();
    this.state = {
      service: [],
      imageService: "",
      nameService: "",
      descriptionService: "",
      title2:'',
       isLoading:true,
    };
  }
  
  getServices = async () => {
    await fetch(Constant.ShowServices, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
           isLoading:false,
           service: data.data,
            imageService: data.data[0].image,
            nameService: data.data[0].name,
            descriptionService: data.data[0].description,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  getTitle = async () => {
    await fetch(Constant.ShowSetting,
     {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            title2: data.data[0].title,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
    };

  componentDidMount() {
    this.getServices();
    this.getTitle();
    // this.props.retrieveServices();
  }
  render() {
    // const { services } = this.props;
    
    return (
      <div>
         <Helmet>
      <meta charSet="utf-8" />
      <title>{this.state.title2} - Services</title>
    </Helmet>
        <section className="section container my-5" id="service">
        <h6 className="opacity mt-5">What I Do ?</h6>
        <h1 className="fw-bold fontt-color">Service</h1>

       
        { this.state.isLoading === true ? 

<div class="row justify-content-center mt-5">
<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
    
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>
 
<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>

<div class="col-lg-4 col-md-4 wow fadeInUp mt-2">
  <div class="service-item position-relative h-100">
    <div
      class="service-text rounded p-5 h-100 shadow">
      <div class="mx-auto mb-4">
        <img className="aboutimg" alt="" />
        <Skeleton  className="aboutimg" width={90}  height={60}/>
      </div>
      <h4 className="mb-3"><Skeleton width={190} /> </h4>
      <p className="mb-0" style={{ textAlign:'justify',fontSize: '15.75px' }}> <Skeleton  count={6}/> </p>
    </div>
  </div>
</div>
</div>

:

              <div className="row justify-content-center mt-5">
                {
                  this.state.service.map((service,i) => {
                    return (
                      <div className="col-lg-4 col-md-4 wow fadeInUp mt-2">
                        <div className="service-item position-relative h-100">
                          <div
                            className="service-text rounded p-5 h-100"
                          >
                            <div className="mx-auto mb-1">

                              <img className="aboutimg" src={Constant2.ShowStorage + service.image} alt="" />
                            </div>
                            <h4 className="mb-3 text fw-bold">{service.name}</h4>
                            <p className="mb-0 fontamy" style={{ textAlign:'justify',fontSize: '15.75px',color: '#0F7A95' }}>  {service.description} </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>

              }
          </section>
          </div>
    )
  }
}

export default Service;
// const mapStateToProps = (state) => {
//   return {
//     services: state.services,
//   };
//   };
  
//   export default connect(mapStateToProps, {
//   retrieveServices,
//   })(Service);
  
  