import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import Header from './components/layout/Header/Header';
import Footer from './components/layout/Footer/Footer';
import Portfolio from './components/Portfolio/Portfolio';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Testmonial from './components/Testmonial/Testmonial';
import Service from './components/Service/Service';
import PortfolioDetail from './components/PortfolioDetail/PortfolioDetail';
import ThankYou from './components/thankyou/ThankYou';
import { SkeletonTheme } from 'react-loading-skeleton';



function App() {
  return (
    <div className="App">
      <SkeletonTheme className="basecolor"  highlightColor="#0F7A95">
     <Header/>
      <Routes>
        <Route>
        <Route path="/" element={<Home />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="portfoliodetail/:id" element={<PortfolioDetail />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="testmonial" element={<Testmonial />} />
        <Route path="service" element={<Service />} />
        <Route path="thankyou" element={<ThankYou />} />
        </Route>
      </Routes>
      <Footer/>
      </SkeletonTheme>
    </div>
  );
}

export default App;
