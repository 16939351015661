import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import "./Footer.css";


// api file
import * as Constant from "../../Constant";
import Skeleton from "react-loading-skeleton";

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      service: [],
      phoneFooter: "",
      emailFooter: "", 
       facebook: "",
      instagram:"",
      linkdin: "",
      google:"",
      title:"",

    isLoading:true,
    };
  }

  getSocials = async () => {
    await fetch(Constant.ShowSocials,
     {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            facebook: data.data[0].facebook,
            instagram: data.data[0].instagram,
            linkdin: data.data[0].linkdin,
            google: data.data[0].google,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };
  getTitle = async () => {
    await fetch(Constant.ShowSetting,
     {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            title: data.data[0].title,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  getContect = async () => {
    await fetch(Constant.ShowCareerUrl,
     {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
           isLoading:false,
           phoneFooter: data.data[0].phone,
           emailFooter: data.data[0].email,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  getServices = async () => {
    await fetch(Constant.ShowServices,
     {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
           isLoading:false,
           service: data.data,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  componentDidMount() {
    this.getContect();
    this.getServices();
    this.getSocials();
    this.getTitle();
  }

  render() {
    return (
      <>
        <React.Fragment>
          <div class="footer">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-sm-4 col-xs-12 text-start mb-5">
                {
                  this.state.isLoading ===true ?
                  <div class="single_footer single_footer_address">
                  <h4>Services</h4>
                  <ul>
                    <li>
                     <Skeleton height={18} width={170}  count={5} style={{marginTop:"5px"}}/>
                    </li>
                  </ul>
                </div>

                  :
                  <div class="single_footer single_footer_address">
                    <h4>Services</h4>
                    {this.state.service.map((service,index)=> { return ( 
                    <ul>
                      <li>
                        <IoIosArrowForward />
                        &nbsp;<Link to="/service">{service.name}</Link>
                      </li>
                    </ul>
                    );})}
                  </div>

}
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 text-start">
                  <div class="single_footer single_footer_address">
                    <h4>Quick Links</h4>
                    <ul>
                      
                   
                      <li>
                        <IoIosArrowForward />
                        &nbsp;<Link to="/about">About</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 text-start position-relative">
                
                      <div class="single_footer single_footer_address">
                        <h4>Contact Us</h4>
                        <div class="signup_form">
                          <form action="#" class="subscribe d-flex">
                            <div class="footer-icon">
                              <span class="fa fa-phone ms-3 mt-3"></span>
                            </div>
                            <a
                            target="_blank"
                              class="footesize mx-4 mt-3"
                              href={"tel://" + this.state.phoneFooter }
                              style={{ color: "#fff" }}
                            >
                              +91 {this.state.phoneFooter}
                            </a>
                          </form>

                          <form action="#" class="d-flex">
                            <div class="footer-icon">
                              <span class="fa fa-paper-plane ms-3 mt-3"></span>
                            </div>
                            <a
                            target="_blank"
                              class="footesize mx-4 mt-3"
                              href={"mailto:" + this.state.emailFooter}
                              style={{ color: "#fff" }}
                            >
                              {this.state.emailFooter}
                            </a>
                          </form>
                        </div>
                      </div>
                      
                  <div class="logonew footerleft">
                    <ul class="">
                      <li>
                        <a href={this.state.facebook} target="_blank">
                          <i class="fab fa-facebook-f icon"></i>{" "}
                        </a>
                      </li>
                      <li>
                        <a href={this.state.instagram} target="_blank">
                          <i class="fab fa-instagram icon"></i>
                        </a>
                      </li>
                      <li>
                        <a href={this.state.linkdin} target="_blank">
                          <i class="fab fa-linkedin-in icon"></i>
                        </a>
                      </li>
                      <li>
                        <a href={"mailto:" + this.state.google} target="_blank">
                          <i class="fab fa-google-plus-g icon"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-sm-12 col-xs-12">
                  <p class="copyright">
                    Copyright © 2023{" "}
                    <a target="_blank"
                      href="https://www.gauravvaghela.com/"
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                    {this.state.title}
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </>
    );
  }
}

export default Footer;


