import React from "react";
import LoadingOverlay from "react-loading-overlay";
import './Contact.css';

import { Helmet } from "react-helmet";

import './Contact.css';
import Skeleton from "react-loading-skeleton";

// api file
import * as Constant from "../Constant";

class Contact extends React.Component {
  constructor() {
    super();
    
    this.state = {
      fullName: "",
      email: "",
      subject: "",
      textarea: "",

      fullNameError: null,
      emailError: null,
      subjectError: null,
      textareaError: null,

      isLoading: false,
      isLoading1: true,

      phoneContect: "",
      emailContect: "",
      linkdinContect: "",
      linkdin_link: "",
      title2: '',
    };

    // this.testFunction();
  }

  validate = () => {
    let fullNameError = "";
    let emailError = "";
    let subjectError = "";
    let textareaError = "";

    if (!this.state.fullName) {
      fullNameError = "Please Enter Full name.";
    }

    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!this.state.email) {
      emailError = "Email Cannot Be Empty.";
    } else {
      if (!reg.test(this.state.email)) {
        emailError = "Please Enter a Valid Email Address.";
      }
    }

    if (!this.state.subject) {
      subjectError = "Subject Cannot Be Empty, Please Enter Subject.";
    }

    if (!this.state.textarea) {
      textareaError = "Message Cannot Be Empty.";
    }

    console.log("fullname", this.state.fullName);
    console.log("email", this.state.email);
    console.log("subject", this.state.subject);
    console.log("textarea", this.state.textarea);

    if (fullNameError || emailError || subjectError || textareaError) {
      this.setState({
        fullNameError,
        emailError,
        subjectError,
        textareaError,
      });
      return false;
    }

    return true;
  };

  onSubmit = () => {
    if (this.validate()) {
      this.setState({
        fullNameError: null,
        emailError: null,
        subjectError: null,
        textareaError: null,
      });
      this.onContact();
    }
  };

  onContact = async () => {
    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append("fullname", this.state.fullName);
    formData.append("email", this.state.email);
    formData.append("subject", this.state.subject);
    formData.append("textarea", this.state.textarea);

    await fetch(Constant.ShowInserts, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            fullName: "",
            email: "",
            subject: "",
            textarea: "",
          });
          window.location.href = Constant.BaseUrl + "thankyou";
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
        this.setState({ isLoading: false });
      });


    };


  getContect = async () => {
    await fetch(Constant.ShowCareerUrl, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            isLoading1:false,
            phoneContect: data.data[0].phone,
            emailContect: data.data[0].email,
            linkdinContect: data.data[0].linkdin_title,
            linkdin_link: data.data[0].linkdin_link,
          
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  getTitle = async () => {
    await fetch(Constant.ShowSetting,
     {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            title2: data.data[0].title,
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  componentDidMount() {
    this.getContect();
    this.getTitle();
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        fadeSpeed={10}
        text="Message Sending..."
      >
       
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{this.state.title2} - Contact Us</title>
          </Helmet>
          
          <React.Fragment>
            <section class="ftco-section">
              <div class="container1 mx-auto my-auto">
                <div class="row justify-content-center">
                  <div class="col-md-6 text-center mb-5">
                    <h2 class="heading-section fontt-color">Contact Us</h2>
                  </div>
                </div>
                <div class="row07 justify-content-center">
                  <div class="col-lg-10 col-md-12">
                    <div class="wrapper">
                      <div class="row no-gutters">
                        <div class="col-md-7 d-flex align-items-stretch border-success green-boder">
                          <div class="contact-wrap w-100 p-md-5 p-4">
                            <h3 class="mb-4 d-flex fontt-color">Get in touch</h3>
                            <div id="form-message-warning" class="mb-4"></div>
                            
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label class="fw-bold d-flex fontt-color">Name:</label>
                                  <input
                                    type="text"
                                    class="form-control border-info"
                                    name="fullName"
                                    placeholder="Name"
                                    onChange={(event) =>
                                      this.setState({
                                        fullName: event.target.value,
                                      })
                                    }
                                    required
                                  />
                                  {!!this.state.fullNameError && (
                                    <div
                                      style={{
                                        color: "red",
                                        textAlign: "start",
                                      }}
                                    >
                                      {this.state.fullNameError}
                                    </div>
                                  )}
                                </div>
                               
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label class="fw-bold d-flex fontt-color">Email:</label>
                                  <input
                                    type="email"
                                    class="form-control border-info"
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                    onChange={(event) =>
                                      this.setState({
                                        email: event.target.value,
                                      })
                                    }
                                    required
                                  />
                                  {!!this.state.emailError && (
                                    <div
                                      style={{
                                        color: "red",
                                        textAlign: "start",
                                      }}
                                    >
                                      {this.state.emailError}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="fw-bold d-flex fontt-color">Subject:</label>
                                  <input
                                    type="text"
                                    class="form-control border-info"
                                    name="subject"
                                    id="subject"
                                    placeholder="Subject"
                                    onChange={(event) =>
                                      this.setState({
                                        subject: event.target.value,
                                      })
                                    }
                                    required
                                  />
                                  {!!this.state.subjectError && (
                                    <div
                                      style={{
                                        color: "red",
                                        textAlign: "start",
                                      }}
                                    >
                                      {this.state.subjectError}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="fw-bold d-flex fontt-color">Message:</label>
                                  <textarea
                                    name="textarea"
                                    class="form-control border-info"
                                    cols="30"
                                    rows="7"
                                    placeholder="Message"
                                    onChange={(event) =>
                                      this.setState({
                                        textarea: event.target.value,
                                      })
                                    }
                                    required
                                  ></textarea>{" "}
                                  {!!this.state.textareaError && (
                                    <div
                                      style={{
                                        color: "red",
                                        textAlign: "start",
                                      }}
                                    >
                                      {this.state.textareaError}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <button
                                    type="submit"
                                    class="btn bg-gradiant d-flex"
                                    onClick={() => this.onSubmit()}
                                  >
                                    Send Message
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                      
                      { this.state.isLoading1 === true ? 
                      
                            <div class="col-md-5 d-flex align-items-stretch mt-5"> 
                              <div class="info-wrap bg-primary w-100 p-lg-5 p-4 rounded-corners">
                                {/* <h3 class="mb-4 mt-md-4 d-flex">Contact us</h3>  */}
                                <Skeleton class="mb-4 mt-md-4 d-flex"height={30} width={100} style={{ left:'-102px', marginBottom:'50px' }} count={1}/>
                                
                                {/* <div class="dbox w-100 d-flex align-items-center"> */}
                                  <Skeleton  class="dbox w-100 d-flex align-items-center" height={25} width={300}  count={1} style={{ marginTop:'25px' }}/>
                                 
                                  <Skeleton class="text-07 pl-3" height={25} width={300}  count={1} style={{ marginTop:'25px' }}/>

                                  <Skeleton class="text-07 pl-3" height={25} width={300}  count={1} style={{ marginTop:'25px' }}/>
                               
                              </div>
                            </div>

                            :
                          
                            <div class="col-md-5 d-flex align-items-stretch"> 
                              <div class="info-wrap bg-primary w-100 p-lg-5 p-4 rounded-corners">
                                <h3 class="mb-4 mt-md-4 d-flex">Contact us</h3> 
                               
                                <div class="dbox w-100 d-flex align-items-start">
                                  {/* <div class="iconn d-flex align-items-center justify-content-center">
                                    <span class="fa fa-map-marker"></span>
                                  </div> */}
                                  {/* <div class="text-07 pl-3">
                                    <p className="text-white">
                                    <span>Address: </span> 
                                      {item.address}
                                    </p>
                                  </div> */}
                                </div>

                                <div class="dbox w-100 d-flex align-items-center">
                                  <div class="icon d-flex align-items-center justify-content-center">
                                    <span class="fa fa-phone"></span>
                                  </div>
                                  <div class="text-07 pl-3">
                                    <p>
                                      <span>Phone:</span>{" "}
                                      <a
                                      target="_blank"
                                        class="text-white"
                                        href={"tel://" + this.state.phoneContect }
                                      >
                                        +91 {this.state.phoneContect }
                                      </a>
                                    </p>
                                  </div>
                                </div>
                                <div class="dbox w-100 d-flex align-items-center">
                                  <div class="icon d-flex align-items-center justify-content-center">
                                    <span class="fa fa-paper-plane"></span>
                                  </div>
                                  <div class="text-07 pl-3">
                                    <p>
                                      <span>Email:</span>{" "}
                                      <a target="_blank"
                                        class="text-white"
                                        href={"mailto:" + this.state.emailContect}
                                      >
                                        {this.state.emailContect}
                                      </a>
                                    </p>
                                  </div>
                                </div>

                                <div class="dbox w-100 d-flex align-items-center">
                                  <div class="icon d-flex align-items-center justify-content-center">
                                    <span class="fa fa-linkedin-in"></span>
                                  </div>
                                  <div class="text-07 pl-3">
                                    <p>
                                      <span>Linkedin:</span>{" "}
                                      <a target="_blank"
                                        class="text-white"
                                        href={this.state.linkdin_link}                                      >
                                        {/* Omega infoway */}
                                        {this.state.linkdinContect}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          
                          }
                          
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </React.Fragment>
        </>
      </LoadingOverlay>
    );
  }
}

 export default Contact;