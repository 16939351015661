import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';
import Carousel1 from "react-bootstrap/Carousel";
import Modal from 'react-bootstrap/Modal';

// api file
import * as Constant from "../Constant";
import * as Constant2 from "../Constant2";





export default function PortfolioDetail(){

  const { id } = useParams();
  const [item, setJournal] = useState(null);
  const [loading ,setLoading] = useState(true);
  const [lgShow, setLgShow] = useState(false);

  useEffect(() => {
    getJournal();
  }, [id]);

  const getJournal = async () => {
    console.log(id)
    const response = await fetch(Constant.ShowPortfoliodetails +'/'+ id);
    const data = await response.json();
    setJournal(data.data);
    setLoading(false);
  };

  let array1 = item?.file_path.split(',');
  console.log('user',array1); 


  // let array1 = item?.file_path.split('/[.,!,?,;,...]/');
    return (
      
      <>
        <section className="container my-5">
          { loading === true ?     
          <div className="row mt-5">
          <div className="col-md-4">
          <Skeleton className="d-lg-flex fw-bold fontt-color" height={300} width={300}  count={1}/>
          </div>
          <div className="col-md-8">
            <div className="row mt-3">   
              <div className="col-md-12">
                <Skeleton className="d-lg-flex opacity" height={30} width={250} count={1}/>
                <h6 className="d-lg-flex opacity">Category:  <Skeleton className="d-lg-flex opacity" height={30} width={200} count={1}/></h6>
               
                 
                  <Skeleton className="d-lg-flex" height={25}  count={4}/>

                <Link
                  type="button"
                  className='btn bg-gradiant w-25 float-end mt-5'
                     onClick={(e) => {
                     e.preventDefault();
                      window.location.href='/';
                            }}
                      >Back</Link>
              </div>
            </div>
          </div>
    
        </div>
          :
    <div className="row mt-5">
      
      <div className="col-md-4">
      {/* <OwlCarousel className='owl-theme' items={1} margin={10} > */}
      <Carousel1>
      { array1.map((item, i) => {
             return (
              <Carousel1.Item>
              <div style={{ height:"366px" }}>
              <button onClick={() => setLgShow(true)}>
                <img src={Constant2.ShowImages + item}  alt="Images is not Load"  style={{ width: "350px" }}/>
                </button>
              </div>
             
              </Carousel1.Item>
             )
            })
          }
          </Carousel1>
           {/* </OwlCarousel> */}

           <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <Carousel1>
      { array1.map((item, i) => {
             return (
              <Carousel1.Item>
              <div>
                <img src={Constant2.ShowImages + item}  alt="Images is not Load"/>
              </div>
             
              </Carousel1.Item>
             )
            })
          }
          </Carousel1>

        </Modal.Body>
      </Modal>
      </div>
     
      <div className="col-md-8">
        <div className="row mt-3">
          
          <div className="col-md-12">
            <h1 className="d-lg-flex fw-bold fontt-color">{item?.title}</h1>
            <h6 className="d-lg-flex opacity">Category: {item?.category}</h6>
            <div className="d-lg-flex">
            <p className="text-start mt-3">
              <div dangerouslySetInnerHTML={ { __html: item?.description}} />
            </p>
            </div>
        
            <Link
              type="button"
              className='btn bg-gradiant w-25 float-end mt-5'
                 onClick={(e) => {
                 e.preventDefault();
                  window.location.href='/';
                        }}
                  >Back</Link>
          </div>
        </div>
      </div>

    </div>

}
  </section>
      </>
      )
    }
    