export const BaseUrlApi = "https://admin.gauravvaghela.com/api/" 
// export const BaseUrlApi = "http://localhost/Admin_Portfolio/api/"

// export const BaseUrl = "http://localhost:3000/"
export const BaseUrl = "https://gauravvaghela.com/"

export const ShowCareerUrl = BaseUrlApi + 'show_contact'
export const ShowStorage = BaseUrl + 'storage'
export const ShowInserts = BaseUrlApi + 'contact_insert'
export const ShowPrivacy = BaseUrlApi + 'privacy'
export const ShowAbout = BaseUrlApi + 'abouts'
export const ShowServices = BaseUrlApi + 'services'
export const ShowSetting = BaseUrlApi + 'settings'
export const ShowPortfolios = BaseUrlApi + 'portfolios'
export const ShowPortfoliodetails = BaseUrlApi + 'portfoliodetails'
export const ShowTestimonial = BaseUrlApi + 'testimonials'
export const ShowSocials = BaseUrlApi + 'socials'




