import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from "./components/scrollToTop";
// import { ApiProvider } from '@reduxjs/toolkit/dist/query/react'
// import { apiSlice } from '../src/reduxtoolkit/features/api/apiSlice'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
     {/* <ApiProvider api={apiSlice}> */}
     {/* <Provider store={store}> */}
     <ScrollToTop />
     <App />
     {/* </Provider> */}
     {/* </ApiProvider> */}
     </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
